export const routes = {
  registration: '/registration',
  login: '/login',
  home: '/',
  recoveryPassword: '/recovery',
  firstVerification: '/verification',
  consent: '/consent',
  verificationCreated: '/verificationCreated',
  settings: '/settings',
  profileSettings: '/settings/profile',
  accessSettings: '/settings/access',
  notifications: '/settings/notifications',
  activity: '/settings/activity',
  deleteAccount: '/settings/delete-account',
  logout: '/logout',
  about: '/about',
  termsAndConditions: '/terms-and-conditions',
  deleteAcountInfo: '/delete-account',
  privacyAndPolicy: '/zamgov/privacypolicy',
  error: '/error',
  faq: '/faq',
};
