import { useState, useCallback, memo, FC, useMemo } from 'react';

import { Button, Icon, TitleContents } from 'components';
import { ButtonStyle, ButtonType, IconSize } from 'enum';
import { getTodayDate, onDownloadPdf, scrollContent } from 'utils';

import styles from './PrivacyAndPolicy.module.scss';

const PDF_FILENAME = 'zampass_privacy_policy';
const MENU_HEIGHT = 76;
const CONTENT_TITLES = [
  'Definitions',
  'Introduction',
  'Owner and Data Controller',
  'Data Collection and Use',
  'Data Processing Roles',
  'User Rights',
  'Additional information about Data collection and processing',
  'Security Measures',
  'Documents Module',
  'Payment Module',
  'Notification Feature',
  'Changes to This Privacy Policy',
];

export const PrivacyAndPolicy: FC = memo(() => {
  const [activeIndex, setActiveIndex] = useState<number>(0);

  const onDownloadHandler = useCallback(async () => {
    const downloadElement = document.getElementById(
      'downloadElement',
    ) as HTMLElement;
    await onDownloadPdf(downloadElement, PDF_FILENAME);
  }, []);

  const onClickTitleHandler = useCallback((index: number) => {
    setActiveIndex(index);
    scrollContent(index, MENU_HEIGHT);
  }, []);

  const today = useMemo(() => {
    return getTodayDate();
  }, []);

  return (
    <div className="container privacy-policy-wrapper py-4">
      <div id="downloadElement" className={`${styles.row} row lh-lg`}>
        <div
          className={`${styles.content} col-lg-9 col-md-8 col-sm-12 mt-3 mt-sm-0`}
        >
          <h1 className="open-sans-bold">Privacy Policy</h1>
          <div className="clearfix mt-3 mt-sm-0">
            <div className="d-inline-block">
              <span className="font-weight-medium last-update">
                Last update: {today}
              </span>
            </div>
            <div className="d-inline-block float-end">
              <Button
                onClick={onDownloadHandler}
                className={`${ButtonStyle.PRIMARY} button-page`}
                type={ButtonType.BUTTON}
                name="download"
              >
                Download PDF{' '}
                <Icon
                  className="text-light align-middle"
                  name="icon-dgpass-download"
                />
              </Button>
            </div>
          </div>
          <div className="pt-4">
            <p className="m-0">
              <b>Privacy Policy for ZamGov</b>
            </p>
            <p className="p-0 m-0">
              To receive information about your Personal Data, the purposes, and
              the parties the Data is shared with, contact the Owner.
            </p>
            <div id="item1" className="info-box">
              <h2 className="open-sans-semibold py-3">1. Definitions</h2>
              <ul>
                <li>
                  <b>User:</b> The individual using this Application who, unless
                  otherwise specified, coincides with the Data Subject.
                </li>
                <li>
                  <b>Data Subject:</b> The natural person to whom the Personal
                  Data refers.
                </li>
                <li>
                  <b>Data Processor (or Data Supervisor):</b> The natural or
                  legal person, public authority, agency, or other body that
                  processes Personal Data on behalf of the Controller, as
                  described in this privacy policy.
                </li>
                <li>
                  <b>Data Controller (or Owner):</b> The natural or legal
                  person, public authority, agency, or other body which, alone
                  or jointly with others, determines the purposes and means of
                  the processing of Personal Data, including the security
                  measures concerning the operation and use of this Application.
                  The Data Controller, unless otherwise specified, is the Owner
                  of this Application.
                </li>
                <li>
                  <b>This Application:</b> How the Personal Data of the User is
                  collected and processed.
                </li>
                <li>
                  <b>Service:</b> The service provided by this Application as
                  described in the relative terms (if available) and on this
                  site/application.
                </li>
              </ul>
            </div>
            <div id="item2" className="info-box">
              <h2 className="open-sans-semibold py-3">2. Introduction</h2>
              <p className="m-0">
                Welcome to ZamGov, a mobile app suite for e-government services
                that provides a secure, centralized application for citizens,
                immigrants, and businesses. It features modules for identity
                verification, digital signatures, document management,
                notifications, and payments, ensuring seamless and secure
                interactions with government entities.
              </p>
              <p>
                This privacy policy explains how we collect, use, and protect
                your personal data when you use our mobile application.
              </p>
            </div>
            <div id="item3" className="info-box">
              <h2 className="open-sans-semibold py-3">
                3. Owner and Data Controller
              </h2>
              <p>
                At ZamGov, one of our main priorities is the privacy of our
                visitors. This Privacy Policy document contains types of
                information collected and recorded by ZamGov and how we use it.
                If you have additional questions or require more information
                about our Privacy Policy, do not hesitate to contact us.
              </p>
            </div>
            <div id="item4" className="info-box">
              <h2 className="open-sans-semibold py-3">
                4. Data Collection and Use
              </h2>
              <p>
                We collect personal data to provide and improve our services,
                ensure the security of your information, and comply with legal
                obligations.
              </p>
            </div>
            <div id="item5" className="info-box">
              <h2 className="open-sans-semibold py-3">
                5. Data Processing Roles
              </h2>
              <ul>
                <li>
                  <b>Data Controller:</b> The entity responsible for determining
                  the purposes and means of processing your personal data. The
                  Data Controller for ZamGov is the owner of this application.
                </li>
                <li>
                  <b>Data Processor:</b> The entity responsible for processing
                  personal data on behalf of the Data Controller.
                </li>
              </ul>
            </div>
            <div id="item6" className="info-box">
              <h2 className="open-sans-semibold py-3">6. User Rights</h2>
              <p className="m-0">
                As a user, you have the right to access, correct, delete, and
                restrict the processing of your personal data. You also have the
                right to data portability and the right to withdraw consent at
                any time, where consent is the legal basis for processing.
              </p>
              <p>In particular, Users have the right to do the following:</p>
              <ul>
                <li>
                  <b>Withdraw their consent at any time.</b> Users have the
                  right to withdraw consent where they have previously given
                  their consent to the processing of their Personal Data.
                </li>
                <li>
                  <b>Object to processing of their Data.</b> Users have the
                  right to object to the processing of their Data if the
                  processing is carried out on a legal basis other than consent.
                  Further details are provided in the dedicated section below.
                </li>
                <li>
                  <b>Access their Data.</b> Users have the right to learn if
                  Data is being processed by the Owner, obtain disclosure
                  regarding certain aspects of the processing and obtain a copy
                  of the Data undergoing processing.
                </li>
                <li>
                  <b>Verify and seek rectification.</b> Users have the right to
                  verify the accuracy of their Data and ask for it to be updated
                  or corrected.
                </li>
                <li>
                  <b>Restrict the processing of their Data.</b> Users have the
                  right, under certain circumstances, to restrict the processing
                  of their Data. In this case, the Owner will not process their
                  Data for any purpose other than storing it.
                </li>
                <li>
                  <b>Have their Personal Data deleted or otherwise removed.</b>
                  Users have the right, under certain circumstances, to obtain
                  the erasure of their Data from the Owner.
                </li>
                <li>
                  <b>
                    Receive their Data and have it transferred to another
                    controller.
                  </b>
                  {
                    "Users have the right to receive their Data in a structured, commonly used, and machine-readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the Data is processed by automated means and that the processing is based on the User's consent, on a contract which the User is part of or on pre-contractual obligations thereof."
                  }
                </li>
                <li>
                  <b>Lodge a complaint.</b> Users have the right to bring a
                  claim before their competent data protection authority.
                </li>
              </ul>
              <p>
                <b>
                  <i>Details about the right to object to processing</i>
                </b>
              </p>
              <p className="m-0">
                Where Personal Data is processed for a public interest, in the
                exercise of an official authority vested in the Owner or for the
                purposes of the legitimate interests pursued by the Owner, Users
                may object to such processing by providing a ground related to
                their particular situation to justify the objection.
              </p>
              <p>
                Users must know that, however, should their Personal Data be
                processed for direct marketing purposes, they can object to that
                processing at any time without providing any justification. To
                learn, whether the Owner is processing Personal Data for direct
                marketing purposes, Users may refer to the relevant sections of
                this document.
              </p>
              <p>
                <b>
                  <i>How to exercise these rights</i>
                </b>
              </p>
              <p>
                Any requests to exercise User rights can be directed to the
                Owner through the contact details provided in this document.
                These requests can be exercised free of charge and will be
                addressed by the Owner as early as possible and always within
                one month.
              </p>
            </div>
            <div id="item7" className="info-box">
              <h2 className="open-sans-semibold py-3">
                7. Additional information about Data collection and processing
              </h2>
              <p>
                <b>
                  <i>Legal action</i>
                </b>
              </p>
              <p>
                {
                  "The User's Personal Data may be used for legal purposes by the Owner in Court or in the stages leading to possible legal action arising from improper use of this Application or the related Services."
                }
              </p>
              <p>
                The User declares to be aware that the Owner may be required to
                reveal personal data upon request of public authorities.
              </p>
              <p>
                <b>
                  <i>{"Additional information about User's Personal Data"}</i>
                </b>
              </p>
              <p>
                In addition to the information contained in this privacy policy,
                this Application may provide the User with additional and
                contextual information concerning particular Services or the
                collection and processing of Personal Data upon request.{' '}
              </p>
              <p>
                <b>
                  <i>Information not contained in this policy</i>
                </b>
              </p>
              <p>
                More details concerning the collection or processing of Personal
                Data may be requested from the Owner at any time. Please see the
                contact information at the footer of this page.
              </p>
              <p>
                <b>
                  <i>Changes to this privacy policy</i>
                </b>
              </p>
              <p className="m-0">
                The Owner reserves the right to make changes to this privacy
                policy at any time by giving notice to its Users on this page
                and possibly within this Application- as far as technically and
                legally feasible – sending a notice to Users via any contact
                information provided to the Owner. It is strongly recommended to
                check this page often, referring to the date of the last
                modification listed at the top.
              </p>
              <p>
                {
                  "Should the changes affect processing activities performed based on the User's consent, the Owner shall collect new consent from the User, where required."
                }
              </p>
            </div>
            <div id="item8" className="info-box">
              <h2 className="open-sans-semibold py-3">8. Security Measures</h2>
              <p>
                We implement robust security measures to ensure the safety of
                your personal data, including:
              </p>
              <ul>
                <li>Encryption of data during transmission and storage.</li>
                <li>Regular security audits.</li>
                <li>Access controls to prevent unauthorized access.</li>
              </ul>
              <p>
                These measures are designed to protect against unauthorized
                access, disclosure, alteration, and destruction of your data.
              </p>
            </div>
            <div id="item9" className="info-box">
              <h2 className="open-sans-semibold py-3">9. Documents Module</h2>
              <p>
                {
                  "The application includes a documents module allowing users to preview and sign documents securely. This module manages user consent, data access permissions, and the application's overall privacy settings."
                }
              </p>
            </div>
            <div id="item10" className="info-box">
              <h2 className="open-sans-semibold py-3">10. Payment Module</h2>
              <p>
                The application includes a payment module that allows users to
                preview invoices and receipts securely. This module adheres to
                the same stringent security measures as the rest of the
                application.
              </p>
            </div>
            <div id="item11" className="info-box">
              <h2 className="open-sans-semibold py-3">
                11. Notification Feature
              </h2>
              <p>
                ZamGov leverages the ZamNotify feature to support dialogue
                between users and government entities. This ensures timely and
                efficient communication regarding user inquiries and government
                notifications.
              </p>
            </div>
            <div id="item12" className="info-box">
              <h2 className="open-sans-semibold py-3">
                12. Changes to This Privacy Policy
              </h2>
              <p>
                We may update this privacy policy from time to time. Any changes
                will be posted on this page, and where appropriate, notified to
                you by email. We encourage you to review this policy
                periodically to stay informed about how we are protecting your
                information.
              </p>
              <p>Thank you for using ZamGov.</p>
            </div>
          </div>
        </div>
        <div className="col-lg-3 col-md-4 col-sm-12">
          <div className={`sticky-top ${styles.sticky}`}>
            <div className="py-2">
              <div className="d-inline-block align-middle">
                <Icon size={IconSize.SIZE_20} name="icon-dgpass-contents" />
              </div>
              <div className="d-inline-block align-middle px-2">
                <h5 className="font-weight-medium">Contents</h5>
              </div>
            </div>
            <div className={`${styles.headings} bg-white`}>
              <TitleContents
                activeIndex={activeIndex}
                onClick={onClickTitleHandler}
                items={CONTENT_TITLES}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
