import { BrowserName, OperatingSystemName, QuestionType } from '../enum';

export const THEMES = {
  light: '/themes/light.css',
  dark: '/themes/dark.css',
};

export namespace Constants {
  export const REQUEST_HEADER = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  };

  export const BROWSER_NAME_MAP = new Map<BrowserName, string>([
    [BrowserName.Edge, 'Edg'],
    [BrowserName.Safari, 'Version'],
    [BrowserName.Mozzila, 'Firefox'],
    [BrowserName.Opera, 'OPR'],
  ]);

  export const OPERATING_SYSTEM_NAME_MAP = new Map<OperatingSystemName, string>(
    [
      [OperatingSystemName.Windows, 'Windows'],
      [OperatingSystemName.MacOs, 'Mac OS'],
      [OperatingSystemName.Linux, 'Linux'],
      [OperatingSystemName.Ios, 'iPhone OS'],
      [OperatingSystemName.Android, 'Android'],
    ],
  );

  export const QUESTIONS = [
    {
      title: 'How do I register an account?',
      text: 'Access ZamPass portal and follow the sign-up steps for registering a ZamPass account.',
      type: QuestionType.Registration,
    },
    {
      title:
        'What do I do if I registered an account but I forgot to complete the verification?',
      text: 'If you already have an account but missed the verification step, you can always verify your account by selecting “Verify account” and entering your national Id.',
      type: QuestionType.Registration,
    },
    {
      title: 'Who can register an account?',
      text: 'To register a ZamPass account, you can either be a Zambian citizen or a foreigner seeking to access and use Zambia electronic public services.',
      type: QuestionType.Registration,
    },
    {
      title: 'How can I cancel/delete my account?',
      text: 'Accounts cannot be removed at this time, but we’re working on this feature and we will provide updates once is ready.',
      type: QuestionType.Registration,
    },
    {
      title: 'Is there a cost for creating a ZamPass account?',
      text: 'There is no cost for using ZamPass by users accessing Zambia electronic public services.',
      type: QuestionType.Registration,
    },
    {
      title: 'How do I access ZamPass on web?',
      text: 'You need to access ZamPass website at <a target="_blank" href="https://zampass.gsb.gov.zm/">https://zampass.gsb.gov.zm/</a>',
      type: QuestionType.WebPortal,
    },
    {
      title: 'What browsers are supported?',
      text: 'It is recommended to access the ZamPass service through the latest three versions of the most popular browsers (Chrome, Edge, Safari, Firefox).',
      type: QuestionType.WebPortal,
    },
    {
      title: 'Can I access ZamPass from a mobile device?',
      text: 'The portal can be accessed both from desktop and mobile devices (with the operating systems iOS, Android).',
      type: QuestionType.WebPortal,
    },
    {
      title: 'Does ZamPass has a mobile app version?',
      text: 'You can register ZamPass account with ZamGov app available for download in Google play and Apple store. While enrolling in the ZamGov app you will also register automatically a ZamPass account.',
      type: QuestionType.Mobile,
    },
    {
      title: 'How can I use ZamGov app for authentication?',
      text: 'You can use ZamGov app for authenticating using the QR sign-in. Also ZamGov app can be used for generating TOTPs (one-time codes) and for receiving MFA push notification.',
      type: QuestionType.Mobile,
    },
    {
      title: 'Can somebody see my login information?',
      text: 'ZamPass administrator can see your username, but nobody have access to your password.',
      type: QuestionType.Passwords,
    },
    {
      title: 'Is my password secure?',
      text: 'ZamPass implements measures to provide high security for password-based flows.',
      type: QuestionType.Passwords,
    },
    {
      title: 'How do I change my password?',
      text: 'To change your existing password, you must first sign-in. Once signed-in, select “Access settings” from the menu, then click “Change password” and provide your new password. If you are not able to update the password, contact support.',
      type: QuestionType.Passwords,
    },
    {
      title: 'What should I do if I have forgotten my password?',
      text: 'If you cannot remember your ZamPas password and need to reset it, click “Recover account” on the sign-in bottom screen and enter you national ID (username). This will allow you to reset your password via your email. You will receive a one-time code that you will use to login and then change your password.',
      type: QuestionType.Passwords,
    },
    {
      title: 'How do I set a strong password?',
      text: '<p>The more complex your password is, the safer it is. ZamPass supports strong passwords by applying the following requirements:</p><ul><li>At least 8 characters</li><li>All characters allowed</li><li>Your password cannot be similar to your user ID</li><li>Passwords previously exposed in data breaches are not allowed</li></ul>',
      type: QuestionType.Passwords,
    },
    {
      title: 'What data ZamPass requires and stores?',
      text: 'Before using ZamPass, the user may be required to provide certain information, such as personal information, identification information, contact details etc. as part of the enrollment process. This information is stored in a secure manner and is not disclosed unless required by law.',
      type: QuestionType.Protection,
    },
    {
      title: 'What is the availability of ZamPass service?',
      text: 'ZamPass is a 24/7 service. The guaranteed period for the agreed service availability level is 98.99%. The maintenance works, normally will be carried out outside working hours, except for emergencies or other unpredictable situations.',
      type: QuestionType.Support,
    },
    {
      title: 'Who do I contact in case of emergencies?',
      text: '<p>In the case of an emergency, contact the support team using the following contact details:</p><p><strong>Phone:</strong> +260 211 428 600, (short code: 2008)</p><p><strong>Email:</strong> info@eservices.gov.zm</p>',
      type: QuestionType.Support,
    },
  ];
}
