export namespace Message {
  export const FOOTER_TEXT =
    'Government of the Republic of Zambia. All Rights Reserved. ZamPass';

  export const TOOLTIP_MFA_MESSAGE =
    'To activate this option you need to have  an Authenticator App (e.g. Google or Microsoft) to scan this QR and use the verification code generated by the app.';

  export const DEFAULT_ERROR_MESSAGE =
    'Something went wrong. Please try again later';

  export const NO_DATA_MESSAGE = 'No data available';
  export const UPDATE_PROFILE_INFORMATION =
    'By clicking Refresh, your information will be updated from National Identity Registry (NIR) records.';
}
