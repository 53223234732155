import { memo, FC, useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { Message } from '../../constants';
import PageLogo from '../../assets/images/zam-footer-logo.svg';
import { routes } from '../../models/routes';

import styles from './Footer.module.scss';

export const Footer: FC = memo(() => {
  const location = useLocation();
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <footer
      className={`${
        location.pathname === routes.consent ? 'd-none' : ''
      } bg-neutral-750 bottom-0 w-100 pt-3 position-relative`}
    >
      <div className={`${styles.footer} container pt-2 pb-1`}>
        <div className="row justify-content-center">
          <div className="col-sm-12 col-md-4 col-lg-4 pb-4 px-5 px-md-4 px-lg-4">
            <div className="d-flex justify-content-center">
              <img
                src={PageLogo}
                alt="DotGov Sign"
                className="img-fluid mb-3"
              />
            </div>
            <div className="d-flex justify-content-center">
              <p className="p4-400 text-white text-center">
                ZamPass - National Digital <br /> Identity and Authentication{' '}
                <br /> Service
              </p>
            </div>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4 pb-4 px-5 px-md-4 px-lg-4">
            <p className="text-white open-sans-light">Useful Links</p>
            <hr className={`${styles.divider}`}></hr>
            <ul className="p-0">
              <Link
                to={routes.about}
                className="nav-link text-link p-0 my-2 p3-400"
              >
                About
              </Link>
              <Link
                to={routes.deleteAcountInfo}
                className="nav-link text-link p-0 my-2 p3-400"
              >
                How to delete my ZamPass account
              </Link>
              <Link
                to={routes.faq}
                className="nav-link text-link p-0 my-2 p3-400"
              >
                FAQ
              </Link>
              <Link
                to={routes.termsAndConditions}
                className="nav-link text-link p-0 my-2 p3-400"
              >
                Terms and Conditions
              </Link>
              <Link
                to={routes.privacyAndPolicy}
                className="nav-link text-link p-0 my-2 p3-400"
              >
                Privacy and Cookies Policy
              </Link>
            </ul>
          </div>
          <div className="col-sm-12 col-md-4 col-lg-4 pb-4 px-5 px-md-4 px-lg-4">
            <p className="mb-2 text-white open-sans-light">Contacts</p>
            <hr className={`${styles.divider}`}></hr>
            <div className="mb-2">
              <p className="text-white p3-400 mb-1">Phone:</p>
              <a className="text-white p1-400">+260 </a>
              <a
                href="tel:+260211 428 600"
                className="p2-400 text-decoration-none"
              >
                211 428 600
              </a>
              <p className="text-muted p4-400 mt-1">(short code: 2008)</p>
            </div>
            <div className="mb-2">
              <p className="text-white p3-400">Email:</p>
              <a href="mailto: info@eservices.gov.zm" className="p3-400">
                info@eservices.gov.zm
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex text-center bg-primary">
        <div className={`${styles.copyright} container open-sans-medium py-1`}>
          <span className="text-white">{`${currentYear} ${Message.FOOTER_TEXT}`}</span>
        </div>
      </div>
    </footer>
  );
});
