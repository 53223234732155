import { FC, memo } from 'react';
import { useNavigate } from 'react-router-dom';

import LOGO from '../../assets/images/zam-header-logo.svg';
import { Button, Message } from 'components';
import { ButtonStyle, ButtonType, MessageType, ServerMessageType } from 'enum';
import { routes } from '../../models/routes';
import { Error } from 'interfaces';

import styles from './VerificationEmail.module.scss';

const MESSAGE: Error = {
  text: 'Dear user, a verification message was sent to your email address. Please verify your email to activate your ZamPass account.',
  type: ServerMessageType.Info,
};

export const VerificationEmail: FC = memo(() => {
  const navigate = useNavigate();
  return (
    <div className="container verification-email-wrapper">
      <div className="d-flex align-items-center justify-content-center vh-100">
        <div className="bg-neutral-100 p-lg-5 p-3 rounded card-max-width">
          <div className="modal-content">
            <div className="align-self-center">
              <img
                src={LOGO}
                alt="ZamPass - National Authentication and Access Control Service"
                className={`${styles.image} img-fluid`}
              />
            </div>
            <div className="py-3">
              <Message type={MessageType.Text} message={MESSAGE} />
            </div>
            <div className="py-2">
              <Button
                key="Close"
                name="Close"
                className={`${ButtonStyle.OUTLINEPRIMARY} w-100`}
                type={ButtonType.BUTTON}
                onClick={() => navigate(routes.login)}
              >
                Close
              </Button>
            </div>
            <div className="pt-3">
              <Button
                key="Resend email"
                name="Resend email"
                className={`${ButtonStyle.OUTLINEPRIMARY} w-100`}
                type={ButtonType.BUTTON}
                onClick={() => navigate(`${routes.firstVerification}`)}
              >
                Resend E-mail Verification
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});
